import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";

import global from "../../global-variables";
import Header from "../layout/header";
import $ from "jquery";
import Metatag from "../../hooks/Metatag";
import {
	BsChevronRight,
	BsLink45Deg,
	BsImages,
	BsFilePdf,
	BsPlayBtn,
} from "react-icons/bs";

import CountUp from "react-countup";

import WOW from "wowjs";
import { Link, useNavigate } from "react-router-dom";
import { GetPublicationOnHomePageList } from "../../api/publications-functions";
import Roadblock_Popup from "./roadblock-popup";
import Visualpanel_Block from "./visualpanel-block";
import NewsTicker_Block from "./news-ticker-block";
import News_Block from "./news-block";
import axios from "axios";
import Event_Block from "./event-block";
import CookieConsent from "react-cookie-consent";

export default function Home() {

	const [publicationList, setPublicationList] = useState();

	const navigate = useNavigate();


	useEffect(() => {
		// add class for home header and remove inner class
		$(".fixed-top").removeClass("bg-header-inner");
		$(".fixed-top").addClass("bg-header-home");


		// initiate the wow effect
		new WOW.WOW({
			live: false,
		}).init();


	}, []);

	// success function when the list is retrived
	const OnGetPublicationHomeSuccess = (p_response) => {
		// set the list for looping
		setPublicationList(p_response);
	}

	// things to be done when the page is loaded
	useEffect(() => {

		// call the function to get the roadblock
		GetPublicationOnHomePageList(OnGetPublicationHomeSuccess, navigate);

	}, []);

	const [academics, setAcademicsList] = useState([]);

	useEffect(() => {
		let _obj = new Object();
		_obj.acad_status = 1;
		axios.post(global.variables.strApiUrl + "/acadApi/getacadList", _obj)
			.then((response_list) => {

				if (response_list.data === "error") {
					navigate("/error-page")
				}
				else {
					// pass the result in the success function of the respective page
					setAcademicsList(response_list.data[0]);
				}

			});

	}, [])

	const [researchcat, setResearchCategoryList] = useState([]);
	useEffect(() => {
		let _obj = new Object();
		_obj.rcategory_status = 1;
		//get research category list
		axios.post(global.variables.strApiUrl + '/rcategoryApi/getrcategoryList', _obj)
			.then((response_rcategory) => {
				if (response_rcategory.data === "error") {
					navigate("/error-page")
				}
				else {
					setResearchCategoryList(response_rcategory.data[0]);
				}

			})
	}, [])

	return (
		<>
			<Header main_class="bg-header-home"></Header>

			<Metatag title="Centre for Machine Intelligence and Data Science | AI and ML Academic Programs at IIT-B"
				description="Artificial Intelligence Academic Programs and Machine Learning Academic Programs, AI-ML Training through cutting-edge research as well as strong industry collaborations offered by IIT Bombay."
				keywords="AI and ML Academic Programs at IIT-B, Artificial Intelligence Training at IIT-Bombay, Machine Learning Training at IIT-Bombay, Data Science Academic Programs at IIT-Bombay, AI-ML Under Graduate Programs, AI-ML Advanced Academic Programs, AI-ML Post Graduate Programs, AI-ML Doctorate Programs, AI-ML PhD Programs"
				url="https://www.minds.iitb.ac.in/"
				robots="index, follow"
			/>

			<Visualpanel_Block />

			<NewsTicker_Block />

			<div className="container-fluid px-0 my-xl-5 my-4">
				<Container className="py-md-5">
					<div className="border-box-1">
						<Row className="px-md-3">
							<Col className="col-lg-7 col-xl-8">
								<div className="h-100 py-md-5">
									<div className="d-flex flex-column h-100 z-index-2 position-relative">
										<div className="px-md-5">
											<div className="hd mb-4">
												<h1 className="fw-bolder m-0 wow fadeInRight">
													<span>About</span> C-MInDS
												</h1>
											</div>
											<p className="wow fadeInUp">
												At the Centre for Machine Intelligence and Data Science (C-MInDS), we are preparing the next generation of scientists, innovators and leaders in Machine learning and Artificial Intelligence. The centre is aimed at expanding the frontiers of AI and ML and their applications through cutting-edge research and advanced academic programs as well as strong industry collaborations.
											</p>
											<div className="pt-4">
												<Link to={global.variables.strDomainUrl + "about"} className="btn btn-primary px-md-4 py-md-3 px-3 py-2 fw-light text-uppercase rounded-0 fw-normal">Read More</Link>
											</div>
										</div>
									</div>
								</div>
							</Col>
							<Col className="col-lg-5 col-xl-4 text-end d-lg-block d-none align-self-center z-index-2">
								<img src={global.variables.strDomainUrl + "images/pic-intro.jpg"} className="img-fluid wow fadeInRight" alt="Centre for Machine Intelligence and Data Science" title="Centre for Machine Intelligence and Data Science" />
							</Col>
						</Row>
					</div>
				</Container>
			</div>

			<div className="container-fluid bg-light px-0 py-5">
				<Container className="my-md-4">
					<div className="hd1 text-center mb-5">
						<h2 className="fw-bolder h1 m-0 wow fadeInUp">
							<span>C-MInDS</span> at a Glance
						</h2>
					</div>
					<Row className="stats">
						<ul className="m-0">
							<li className="wow fadeInUp">
								<h2 className="fw-bolder text-primary m-0 display-2">
									<CountUp start={0} end={80} enableScrollSpy />+
								</h2>
								<h3 className="h5 fw-normal">Associated Faculty from 15 Departments</h3>
							</li>
							<li className="wow fadeInUp">
								<h2 className="fw-bolder text-primary m-0 display-2">
									<CountUp start={0} end={100} enableScrollSpy />+
								</h2>
								<h3 className="h5 fw-normal">Current Students and Alumni</h3>
							</li>
							<li className="wow fadeInUp">
								<h2 className="fw-bolder text-primary m-0 display-2">
									<CountUp start={0} end={45} enableScrollSpy />+
								</h2>
								<h3 className="h5 fw-normal">Research Projects Completed</h3>
							</li>
							<li className="wow fadeInUp">
								<h2 className="fw-bolder text-primary m-0 display-2">
									<CountUp start={0} end={35} enableScrollSpy />+
								</h2>
								<h3 className="h5 fw-normal">Industry Engagements</h3>
							</li>
						</ul>
					</Row>
				</Container>
			</div>

			<div className="container-fluid bg-light px-0">
				<div className="container-1920 px-0 bg-academics py-md-5">
					<Container>
						<div className="col-xl-6 ps-xl-5 ms-auto py-4  px-3">
							<div className="academics-info ms-xl-5">
								<div className="hd mb-3 mb-lg-3">
									<h2 className="fw-bolder h1 m-0 wow fadeInRight">
										<span className="text-white">Academic</span> Programs
									</h2>
								</div>
								<p className="wow fadeInUp">
									We offer comprehensive academic programs that range from
									foundations and essentials of AI at the under-graduate level to
									a vast breadth of advanced topics and path-breaking research at
									the master's and doctorate levels.
								</p>
								<ul class="list-group list-group-flush">
									{
										academics.map((val) => {
											return (
												<li class="list-group-item h5 fw-bold wow fadeInUp" key={val.acad_id}>
													<Link to={global.variables.strDomainUrl + "academic-details/" + val.acad_pagename}>{val.acad_course_name}</Link>
												</li>
											)

										})
									}
									<li class="list-group-item h5 fw-bold wow fadeInUp">
										<Link to={global.variables.strDomainUrl + "admissions"}>Admissions</Link>
									</li>


								</ul>

								<div className="mt-lg-5 mt-4">
									<Link to={global.variables.strDomainUrl + "academics"} className="btn btn-primary px-md-4 py-md-3 px-3 py-2 fw-light text-uppercase rounded-0 fw-normal text-white">Read More</Link>
								</div>
							</div>
						</div>
					</Container>
				</div>
			</div>

			{
				researchcat != null ?
					researchcat.length > 0 ?
						<div className="container-fluid px-0 my-md-5">
							<Container className="py-5">
								<div className="mb-5 text-center">
									<div className="hd1 text-center mb-3">
										<h2 className="fw-bolder h1 m-0 wow fadeInUp">
											<span>Research</span> Areas
										</h2>
									</div>
									<div className="col-lg-9 col-xl-8 m-auto wow fadeInUp">
										A detailed highlight of research, both theoretical and
										applications-oriented, being carried out by different faculty
										members at IIT Bombay can be found in the following:
									</div>
								</div>
								<Row className="row-cols-2 row-cols-md-3 row-cols-xl-5 g-md-4 g-3 g-lg-4 justify-content-center">

									{
										researchcat.map((val) => {
											return (
												<Col className="text-center">
													<div className="btn-5 w-100 h-100">
														<figure className="p-2 p-md-3">
															<img src={global.variables.strApiUrl + "/researchcategory-image/" + val.rcategory_icon} className="img-fluid mb-3 wow zoomIn" alt={val.rcategory_name} title={val.rcategory_name} />
															<h5 className="m-0 fw-bold wow fadeInUp">{val.rcategory_name}</h5>
														</figure>
													</div>
												</Col>
											)
										})
									}

								</Row>

								<div className="mt-lg-5 mt-4 text-center wow fadeInUp">
									<Link to={global.variables.strDomainUrl + "research"} className="btn btn-primary px-md-4 py-md-3 px-3 py-2 fw-light text-uppercase rounded-0 fw-normal">Read More</Link>
								</div>
							</Container>
						</div>
						: null
					: null
			}


			{
				publicationList != null ?
					publicationList.length > 0 ?
						<div className="container-fluid px-0 bg-light py-md-5">
							<Container className="py-5">
								<div className="hd1 text-center mb-5">
									<h2 className="fw-bolder h1 m-0 wow fadeInUp">
										<span>Recent</span> Publications
									</h2>
								</div>
								<Row className="row-cols-1 row-cols-md-3 row-cols-xl-5 g-4 publications">
									{
										publicationList.map((val) => {

											return (
												<Col>
													<Link to={global.variables.strDomainUrl + "publication-details/" + val.pub_pagename}>
														<div className="boder publication-box filled border-primary p-4 h-100">
															{
																val.pub_topaper != null && val.pub_topaper != '' ?
																	<p className="wow fadeInUp">{val.pub_topaper}</p>
																	:
																	null
															}
															{
																val.pub_dep != null && val.pub_dep != '' ?
																	<p className="m-0 wow fadeInUp">
																		<b>{val.pub_fac_name}</b>
																		<br />{val.pub_dep}
																	</p>
																	:
																	<p className="m-0 wow fadeInUp">
																		<b>{val.pub_fac_name}</b>
																	</p>
															}
														</div>
													</Link>
												</Col>

											)
										})
									}
								</Row>
								<div className="mt-lg-5 mt-4 text-center wow fadeInUp">
									<Link to={global.variables.strDomainUrl + "publications/" + publicationList[0].pub_year} className="btn btn-primary px-md-4 py-md-3 px-3 py-2 fw-light text-uppercase rounded-0 fw-normal">View All</Link>
								</div>
							</Container>
						</div>
						:
						null
					:
					<div className="mt-lg-5 mt-4 wow fadeInUp">
						Loading ...
					</div>
			}

			<Container fluid className="bg-collaboration-main px-0">
				<div className="container-1920 px-0 bg-collaboration py-md-5">
					<Container className="container">
						<Col xl={6} className="pe-xl-5 py-4 px-3">
							<div className="collaboration-info me-xl-5">
								<div className="hd mb-3 mb-lg-3">
									<h2 className="fw-bolder h1 m-0 wow fadeInRight">Our Collaborations</h2>
								</div>
								<p className="wow fadeInUp">Fostering  partnerships for undertaking AI projects with societal and industrial impact</p>
								<ul className="list-group list-group-flush">
									<li className="list-group-item h5 fw-bold wow fadeInUp"><Link to="/collaborationdetails">SBI Technology Hub for Data Science <br className="d-lg-block d-none"/>and Analytics</Link></li>
								</ul>
								<div className="mt-lg-5 mt-4">
									<Link className="btn btn-primary px-md-4 py-md-3 px-3 py-2 fw-light text-uppercase rounded-0 fw-normal text-white" to="/collaboration">Read More</Link>
								</div>
							</div>
						</Col>
					</Container>
				</div>
			</Container>

			<div className="container-fluid my-md-5 px-0">
				<Container className="py-5">
					<div className="row gy-5">
						<News_Block />

						<Event_Block />
					</div>
				</Container>
			</div>
			<Roadblock_Popup />

			<CookieConsent
				location="bottom"
				buttonText="GOT IT"
				buttonClasses="btn rounded-0 text-white px-3"
				containerClasses="cookie-wrapper"
				contentClasses="cookie-content"
				cookieName="cookiesAccepted"
				expires={150}
				className="cookie-popup-inner"
			>We use cookies to ensure that we give you the best experience and to analyze our website traffic and performance; we never collect any personal data.</CookieConsent>
		</>
	);
}