import React, { useState, useEffect} from 'react';
import Header from "../layout/header";
import { Container, Row, Col } from 'reactstrap';
import global from "../../global-variables";

import Metatag from "../../hooks/Metatag";
import { Link } from "react-router-dom";
import axios from  'axios';
import {useNavigate } from "react-router-dom";
import globalVariables from '../../global-variables';

export default function Sitemap() {

  const [academics, setAcademicsList] = useState([]);

  const [researchcat, setResearchCategoryList] = useState([]);

  const navigate =  useNavigate();

  useEffect(()=>{
      let _obj = new Object();
      _obj.acad_status =1;

      axios.post(global.variables.strApiUrl + "/acadApi/getacadList",_obj)
      .then((response_list) => {

        if(response_list.data === "error")
        {
             navigate("/error-page")
        }
        else
        {
           // pass the result in the success function of the respective page
           setAcademicsList(response_list.data[0]);
        }
         

      });

  },[])

  useEffect(() => {
    let _obj = new Object();
    _obj.rcategory_status = 1;
      //get research category list
      axios.post(global.variables.strApiUrl + '/rcategoryApi/getrcategoryList',_obj)
          .then((response_rcategory) => {

            if(response_rcategory.data === "error")
            {
                 navigate("/error-page")
            }
            else
            {
              setResearchCategoryList(response_rcategory.data[0]);
            }
            
          }) 
  }, [])

  const [newstypeyear, setNewsTypeYearList] = useState(null);

  const [newsletteryear, setNewsLetterYearList] = useState(null);

  const [publicationyear, setPublicationYearList] = useState(null);

  const [reportyear, setReportYearList] = useState(null);

  // things to be done when the page is loaded
  useEffect(() => {

    //make the object for getting the news type year list
    let _objNewsTypeYear = new Object();
    _objNewsTypeYear._news_type_id = globalVariables.variables.strNewsTypeForNews_id;

    //Get news type yesr List
    axios.post(globalVariables.variables.strApiUrl + "/newsApi/getnewstypedistinctyear", _objNewsTypeYear)
    .then((response_list) => {

        if(response_list.data === "error")
        {
            navigate("/error-page")
        }
        else
        {
            // pass the result in the success function of the respective page
            setNewsTypeYearList(response_list.data[0][0]?.news_year);
            
        }
        
    });


    //make the object for getting the report year list
    let _objNLetterYear = new Object();
    _objNLetterYear.nletter_status = 1;

   //Get news type yesr List
   axios.post(globalVariables.variables.strApiUrl + "/nletterApi/getnletterdistinctyear", _objNLetterYear)
   .then((response_list) => {

       if(response_list.data === "error")
       {
           navigate("/error-page")
       }
       else
       {
           // pass the result in the success function of the respective page
           setNewsLetterYearList(response_list.data[0][0]?.nletter_year);
           
       }
       
   });

    //make the object for getting the news type year list
    let _objPublicationYear = new Object();
    _objPublicationYear.pub_status = 1;

  //Get pub type yesr List
  axios.post(globalVariables.variables.strApiUrl + "/publicationsApi/getpublicationsdistinctyearForWebadmin", _objPublicationYear)
  .then((response_list) => {

      if(response_list.data === "error")
      {
          navigate("/error-page")
      }
      else
      {
          // pass the result in the success function of the respective page
          setPublicationYearList(response_list.data[0][0]?.pub_year);  
      }
      
  });


    //make the object for getting the report year list
    let _objReportYear = new Object();
    _objReportYear.report_id = null;
    _objReportYear.report_status = 1;

  //Get report type yesr List
  axios.post(globalVariables.variables.strApiUrl + "/reportApi/getreportdistinctyearForWebadmin", _objReportYear)
  .then((response_list) => {

      if(response_list.data === "error")
      {
          navigate("/error-page")
      }
      else
      {
          // pass the result in the success function of the respective page
          setReportYearList(response_list.data[0][0].report_year);
          
      }
      
  });

}, []);

  return (
    <>
               <Header main_class="bg-header-home"></Header>
               <Metatag title="Center of Machine Intelligence and Data Science" keywords="Center of Machine Intelligence and Data Science" description="Center of Machine Intelligence and Data Science"/>
                {/* Visual Container */}
                <div className="container-fluid visual-container-1920 px-0">
                    <div className="bg-visual-inside position-relative" style={{ backgroundImage: "url('./images/masthead-about.jpg')" }}>
                        <img src={global.variables.strDomainUrl + "images/masthead-spacer.png"} className="img-fluid" alt="" title="" />
                        <Container className="visual-text mx-auto">
                            <h1 className="fw-semibold wow fadeInUp">Sitemap</h1>
                        </Container>
                    </div>
                </div>

                {/* Body Panel */}
                <div className="container-fluid py-md-5" id="sitemap">
                    <Container className="py-4">
                        <Row className="row-cols-1 row-cols-lg-4 g-5 px-0">
                            <Col className="pe-lg-5">
                              <h6>About</h6>
                              <ul>
                                <li><Link to="/about">Overview</Link></li>
                                <li><Link to={"/about/" + global.variables.strPeopleCategoty_pagename}>Founders and Donors</Link></li>
                                <li><Link to={reportyear != null ? "/reports/" + reportyear : "/reports/" + new Date().getFullYear()}>Reports</Link></li>
                                <li><Link to="/recruitment">Recruitment</Link></li>
                              </ul>

                              <h6><Link to="/people">People</Link></h6>
                              <h6><Link to="/seminars/recent">Seminar</Link></h6>
                              <h6><Link to="/recruitment">Recruitment</Link></h6>
                              <h6><Link to="/contact">Contact Us</Link></h6>
                            </Col>
                            <Col className="ps-lg-5">
                                <h6>Academics</h6>
                                <ul>
                                  <li><Link to="/academics">Overview</Link></li>
                                  {
                                    academics.length  > 0 ?
                                      academics?.map((val)=>{
                                          return(
                                              <li><Link to={"/academic-details/" + val.acad_pagename}>{val.acad_course_name}</Link></li>  
                                          )
                                      })
                                      : null
                                    }
                                  <li><Link to="/admissions">Admission</Link></li>
                                </ul>
                            </Col>
                            <Col className="ps-lg-5">
                            <h6>Research</h6>
                              <ul>
                                  <li><Link to="/research">Overview</Link></li>
                                  {
                                    researchcat.length > 0 ?
                                    researchcat?.map((val)=>{
                                        return(
                                          <li><Link to={"/research-project/" + val.rcategory_id}>{val.rcategory_name}</Link></li>)
                                        
                                    })
                                    :null
                                  }
                                  <li><Link to={publicationyear != null ? "/publications/" + publicationyear : "/publications/" + new Date().getFullYear()}>Publications</Link></li>
                                </ul>
                            </Col>
                            <Col className="ps-lg-5">
                            <h6>News & Events</h6>
                              <ul>
                                  <li><Link to={ newstypeyear != null ? "/news/" + newstypeyear : "/news/" + new Date().getFullYear()}>News & Updates</Link></li>
                                  <li><Link to="/events/recent">Events</Link></li>
                                  <li><Link to={newsletteryear != null ? "/newsletter/" + newsletteryear : "/newsletter/" + new Date().getFullYear()}>Newsletter</Link></li>
                                </ul>
                                

                            <h6><Link to="/collaboration">Collaboration</Link></h6>
                              {/* <ul>
                                  <li><Link to="/externalcollaboration">External Collaboration</Link></li>
                                  <li><Link to="#">Outreach</Link></li>
                                  <li><Link to="#">Training Programs</Link></li>
                                </ul> */}
                            </Col>
                            
                        </Row>
                    </Container>
                </div>
    </>
    
  )
}
