import React, { useEffect, useState } from 'react';
import Header from "../layout/header";
import { Container, Row, Col } from 'reactstrap';
import global from "../../global-variables";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { BsChevronRight } from "react-icons/bs";
import WOW from "wowjs";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Metatag from "../../hooks/Metatag";
import { useLocation,useParams } from "react-router-dom";

export default function PublicationDetails() {

    
    // const [researchMapPeople, setResearchMapPeopleList] = useState([]);
    
    const [publications, setPublicationsList] = useState([]);
    
    let {pub_pagename} = useParams();
    const qs_pub_pagename = pub_pagename;

    const navigate = useNavigate();

    //research categoty based project list
    useEffect(() => {
        let _obj = new Object();
        _obj.pub_pagename = qs_pub_pagename;
        //get research category list
        axios.post(global.variables.strApiUrl + '/publicationsApi/getpublicationsList',_obj)
            .then((response) => {
                if(response.data === "error")
                {
                     navigate("/error-page")
                }
                else
                {
                    setPublicationsList(response.data[0]);
                    // //get research map people list
                    // let _objresmap_people = new Object();
                    // _objresmap_people.res_id = response_research.data[0][0].res_id;
                    // //get research category list
                    // axios.post(global.variables.strApiUrl + '/researchApi/getresearchMapPeopleList',_objresmap_people)
                    //     .then((response_research_m_ppl) => {

                    //         if(response_research_m_ppl.data === "error")
                    //         {
                    //              navigate("/error-page")
                    //         }
                    //         else
                    //         {
                    //             setResearchMapPeopleList(response_research_m_ppl.data[0]);
                    //         }
                    // }) 
                }

                }) 
    }, [])

    useEffect(()=>{

        // initiate the wow effect
		new WOW.WOW({
			live: false,
		}).init();

    },[publications])
    return (
        <>
            <Header main_class="bg-header-home"></Header>
            {/* dangerouslySetInnerHTML={{ __html: research[0]?.res_fac_members }} */}
            {
                publications[0] != null && publications[0] != undefined ?
                // <Metatag title={ research[0]?.res_pro_name } keywords={research[0]?.res_pro_name + " | " + research[0]?.res_fac_members} description={research[0]?.res_pro_name + " | " + research[0]?.res_fac_members}/>
                <Metatag title={ publications[0]?.pub_pagetitle }
                            keywords={publications[0]?.pub_meta_keywords}
                            description={publications[0]?.pub_meta_description}/>
                :null
            }

            {/* Visual Container */}
            <div className="container-fluid visual-container-1920 px-0">
                <div className="bg-visual-inside position-relative" style={{ backgroundImage: "url('../images/masthead-research.jpg')" }}>
                    <img src={global.variables.strDomainUrl + "images/masthead-spacer.png"} className="img-fluid" alt="" title="" />
                    <Container className="visual-text mx-auto">
                    <h1 className="fw-semibold wow fadeInUp">Publications</h1>
                    </Container>
                </div>
            </div>

            {/* Body Panel */}
            <div className="container-fluid my-md-5 py-4">
                <Container className="px-0">
                    <div className="border-1 border-primary">
                        <div className="h-100 p-lg-5">
                            {
                                 publications != null ?
                                 publications.length > 0 ? 
                                    <div className="position-relative">
                                        {/* {
                                            publications?.map((val)=>{
                                                return( */}
                                                    <>
                                                         <Row>
                                                               
                                                                <Col md={12} lg={12} className="px-xl-4">
                                                                    <div className="mb-3">
                                                                        {
                                                                            publications[0].pub_topaper != null && publications[0].pub_topaper != "" ? 
                                                                            <>
                                                                                <h5 className="text-primary mb-3 wow fadeInUp">{publications[0].pub_topaper}</h5>
                                                                            </>
                                                                            :
                                                                            null
                                                                        }
                                                                        <div className="publications-table table-responsive position-relative wow fadeInUp">
                                                                            <table className="table table-borderless">
                                                                                <tbody>
                                                                                {
                                                                                publications[0].pub_reference != null && publications[0].pub_reference != "" ?
                                                                                    <tr>
                                                                                        <td style={{width:90}} className='fw-bold ps-0'>Reference</td>
                                                                                        <td style={{width:5}} className='text-center'>:</td>
                                                                                        <td>{publications[0].pub_reference}</td>
                                                                                    </tr>
                                                                                :
                                                                                null
                                                                                }
                                                                                {
                                                                                publications[0].pub_fac_name != null && publications[0].pub_fac_name != "" ?
                                                                                    <tr>
                                                                                        <td className='fw-bold ps-0'>Faculty </td>
                                                                                        <td style={{width:5}} className='text-center'>:</td>
                                                                                        <td>{publications[0].pub_fac_name}</td>
                                                                                    </tr>
                                                                                :
                                                                                null
                                                                                }
                                                                                {
                                                                                publications[0].pub_dep != null && publications[0].pub_dep != "" ?
                                                                                    <tr>
                                                                                        <td className='fw-bold ps-0'>Department</td>
                                                                                        <td style={{width:5}} className='text-center'>:</td>
                                                                                        <td>{publications[0].pub_dep}</td>
                                                                                    </tr>
                                                                                :
                                                                                null
                                                                                }
                                                                                {
                                                                                publications[0].pub_authers != null && publications[0].pub_authers != "" ?
                                                                                    <tr>
                                                                                        <td className='fw-bold ps-0'>Authors</td>
                                                                                        <td style={{width:5}} className='text-center'>:</td>
                                                                                        <td>{publications[0].pub_authers}</td>
                                                                                    </tr>
                                                                                :
                                                                                null
                                                                                }
                                                                                </tbody>
                                                                            </table>
                                                                            </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            
                                                            <Row>
                                                                

                                                                {/* {
                                                                    val.res_map_ppl_count > 0 ? 
                                                                        <Col md={12} className="mt-5">
                                                                        <div className="mb-3">
                                                                            <h5 className="mb-2">Faculty Members:</h5>
                                                                            <Row className="row-cols-2 row-cols-md-4 row-cols-lg-6 g-3 g-lg-5">
                                                                               
                                                                                {
                                                                                    researchMapPeople?.map((val)=>{
                                                                                        return(
                                                                                            <Col className="text-center" key={val.people_id}>

                                                                                                {                                                                                           
                                                                                                    // (val.people_publications != null && val.people_publications != "") || (val.people_research_inst != null && val.people_research_inst != "") || (val.people_profile_brief != null && val.people_profile_brief != "") ?
                                                                                                        <Link to={global.variables.strDomainUrl + "people/" + val.pcategory_pagename + "/" + val.people_pagename}>
                                                                                                            {
                                                                                                                val.people_pic != null && val.people_pic != "" ? 
                                                                                                                <img src={global.variables.strApiUrl + "/people/" + val.people_pic} className="img-fluid border mb-lg-2 mb-1 wow fadeIn" alt={val.people_name} title={val.people_name} />
                                                                                                                : 
                                                                                                                <img src={DefaultImg} className="img-fluid border mb-lg-2 mb-1 wow fadeIn" alt={val.people_name} title={val.people_name}/>

                                                                                                            }
                                                                                                            <small>{val.people_name}</small>
                                                                                                        </Link>
                                                                                                        //  :
                                                                                                        // <>
                                                                                                        //     {
                                                                                                        //         val.people_pic != null && val.people_pic != "" ? 
                                                                                                        //         <img src={global.variables.strApiUrl + "/people/" + val.people_pic} className="img-fluid border mb-lg-2 mb-1 wow fadeIn" alt={val.people_name} title={val.people_name} />
                                                                                                        //         : 
                                                                                                        //         <img src={DefaultImg} className="img-fluid border mb-lg-2 mb-1 wow fadeIn" alt={val.people_name} title={val.people_name}/>

                                                                                                        //     }
                                                                                                        //     <small>{val.people_name}</small>
                                                                                                        // </>
                                                                                                }
                                                                                            </Col>
                                                                                        )
                                                                                    })
                                                                                }
                                                                               
                                                                            </Row>
                                                                        </div>
                                                                         </Col>
                                                                    : null
                                                                } */}
                                                                 
                                                            </Row>
                                                            <div className="d-flex flex-wrap justify-content-end gap-3 mt-4">
                                                                <Link  className="btn btn-primary px-md-4 py-md-3 px-3 py-2 fw-light text-uppercase rounded-0 fw-normal" onClick={() => navigate(-1)}>
                                                                    Back
                                                                </Link>
                                                            </div>
                                                    </>
                                                {/* )
                                            })
                                        }   */}
                                    </div>
                                    :null
                                    
                                :
                                 <Container className="py-md-4 py-4">
                                    <Row>
                                        <span className="text-center">Loading...</span>
                                    </Row>
                                </Container>
                            }
                          
                        </div>
                    </div>
                </Container>
        </div>
            {/* Breadcrumb */}
            <Breadcrumb className="breadcrumb-panel px-3">
                <Breadcrumb.Item href={global.variables.strDomainUrl}>Home</Breadcrumb.Item>
                <Breadcrumb.Item href={global.variables.strDomainUrl + "publications/" + publications[0]?.pub_year}>Publications</Breadcrumb.Item>
                {/* <Breadcrumb.Item active>{publications[0]?.pub_topaper}</Breadcrumb.Item> */}

                
            </Breadcrumb>
        </>
    );
};