import React, { useEffect, useState } from 'react';
import global from "../../global-variables";
import { Link, useNavigate } from "react-router-dom";
import { FaFacebookF, FaTwitter, FaYoutube, FaWhatsapp, FaLinkedinIn, FaInstagram, FaPinterest, FaBlogger } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import WOW from 'wowjs';
import axios from "axios";
import ScrollToTop from "react-scroll-to-top"
import { Container, Row, Col } from "reactstrap";

export default function Footer() {

    const [academics, setAcademicsList] = useState([]);
    const [socialMediaList, setSocialMediaList] = useState([])

    const [setting, setSettingList] = useState([]);
    const [researchcat, setResearchCategoryList] = useState([]);

    const [newstypeyear, setNewsTypeYearList] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        let _obj = new Object();
        _obj.setting_id = global.variables.strSetting_id;
        axios.post(global.variables.strApiUrl + "/settingApi/getCompanySettingList", _obj)
            .then((response_list) => {

                if (response_list.data === "error") {
                    navigate("/error-page")
                }
                else {
                    // pass the result in the success function of the respective page
                    setSettingList(response_list.data[0]);
                }

            });
    }, [])


    useEffect(() => {
        let _obj = new Object();
        _obj.acad_status = 1;
        setTimeout(() => {

            axios.post(global.variables.strApiUrl + "/acadApi/getacadList", _obj)
                .then((response_list) => {

                    if (response_list.data === "error") {
                        navigate("/error-page")
                    }
                    else {
                        // pass the result in the success function of the respective page
                        setAcademicsList(response_list.data[0]);
                    }

                });
        }, 300);


        //make the object for getting the news type year list
        let _objNewsTypeYear = new Object();
        _objNewsTypeYear._news_type_id = global.variables.strNewsTypeForNews_id;

        //Get news type yesr List
        axios.post(global.variables.strApiUrl + "/newsApi/getnewstypedistinctyear", _objNewsTypeYear)
        .then((response_list) => {

            if(response_list.data === "error")
            {
                navigate("/error-page")
            }
            else
            {
                // pass the result in the success function of the respective page
                setNewsTypeYearList(response_list.data[0][0]?.news_year);
                
            }
            
        });

    }, [])

    useEffect(() => {
        let _obj = new Object();
        _obj.social_status = 1;
        setTimeout(() => {

            axios.post(global.variables.strApiUrl + "/socialApi/getSocialList", _obj)
                .then((response_list) => {

                    if (response_list.data === "error") {
                        navigate("/error-page")
                    }
                    else {
                        // pass the result in the success function of the respective page
                        setSocialMediaList(response_list.data[0]);
                    }

                });
        }, 300);

    }, [])

    useEffect(() => {

        // initiate the wow effect
        new WOW.WOW({
            live: false
        }).init();

    }, []);


    //get reserach category list
    useEffect(() => {
        let _obj = new Object();
        _obj.rcategory_status = 1;
        //get research category list
        axios.post(global.variables.strApiUrl + '/rcategoryApi/getrcategoryList', _obj)
            .then((response_rcategory) => {
                if (response_rcategory.data === "error") {
                    navigate("/error-page")
                }
                else {
                    setResearchCategoryList(response_rcategory.data[0]);
                }

            })
    }, [])

    return (
        <footer className="mt-auto">
            <div className="container-xxl py-lg-5">
                <div className="row py-lg-4 py-md-5 py-4">
                    <div className="col-md-8 col-lg-4 col-xl-4 mb-md-0 mb-4">
                        {
                            setting.map((val) => {
                                return (
                                    <>

                                        <h6 className="text-uppercase wow fadeInUp">{val.setting_company}</h6>

                                        <p className="wow fadeInUp">{val.setting_address + ","}<br />
                                            {val.setting_city} {val.setting_pin}{val.setting_state != null && val.setting_state != "" ? <>{", " + val.setting_state}</> : null}{
                                                val.setting_country != null && val.setting_country != "" ? <>{", " + val.setting_country}</> : null}
                                        </p>


                                        <p className="wow fadeInUp">


                                            {
                                                val.setting_phone != null && val.setting_phone != "" ?
                                                    <><b>T:</b> {val.setting_phone}<br /></>
                                                    : null
                                            }
                                            <b>E:</b> <span>{val.setting_contact_email}</span>
                                        </p>
                                    </>
                                )


                            })
                        }


                        <ul className="social_media d-none d-md-block">
                            {
                                socialMediaList?.length > 0 ?

                                    socialMediaList?.map((val, index) => {
                                        return (
                                            <li className="wow fadeInUp" key={index}>
                                                {
                                                    val.social_img_name === 'FaFacebookF' ?
                                                        <a href={val.social_url} rel="noopener noreferrer" target="_blank" className="facebook" ><FaFacebookF className="icon-24" /></a>
                                                        :
                                                        null

                                                }
                                                {
                                                    val.social_img_name === 'FaYoutube' ?
                                                        <a href={val.social_url} rel="noopener noreferrer" target="_blank" className="youtube"><FaYoutube className="icon-24" /></a>
                                                        :
                                                        null

                                                }
                                                {
                                                    val.social_img_name === 'FaXTwitter' ?
                                                        <a href={val.social_url} rel="noopener noreferrer" target="_blank" className="twitter"><FaXTwitter className="icon-24" /></a>
                                                        :
                                                        null

                                                }
                                                {
                                                    val.social_img_name === 'FaWhatsapp' ?
                                                        <a href={val.social_url} rel="noopener noreferrer" target="_blank" className="whatsapp"><FaWhatsapp className="icon-24" /></a>
                                                        :
                                                        null

                                                }
                                                {
                                                    val.social_img_name === 'FaInstagram' ?
                                                        <a href={val.social_url} rel="noopener noreferrer" target="_blank" className="instagram"><FaInstagram className="icon-24" /></a>
                                                        :
                                                        null

                                                }
                                                {
                                                    val.social_img_name === 'FaLinkedinIn' ?
                                                        <a href={val.social_url} rel="noopener noreferrer" target="_blank" className="linkedin"><FaLinkedinIn className="icon-24" /></a>
                                                        :
                                                        null

                                                }
                                                {
                                                    val.social_img_name === 'FaPinterest' ?
                                                        <a href={val.social_url} rel="noopener noreferrer" target="_blank" className="pinterest"><FaPinterest className="icon-24" /></a>
                                                        :
                                                        null

                                                }
                                                {
                                                    val.social_img_name === 'FaBlogger' ?
                                                        <a href={val.social_url} rel="noopener noreferrer" target="_blank" className="blogger"><FaBlogger className="icon-24" /></a>
                                                        :
                                                        null

                                                }
                                            </li>
                                        )

                                    })

                                    :
                                    null

                            }
                        </ul>
                    </div>
                    <div className="col-md-4 col-lg-8 col-xl-8">
                        <div className="d-flex justify-content-between">
                            <div className="d-lg-block d-none">
                                <h6 className="text-uppercase wow fadeInUp">Academics</h6>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item wow fadeInUp"><Link to="/academics">Overview</Link></li>

                                    {
                                        academics.map((val) => {
                                            return (
                                                <li className="list-group-item wow fadeInUp" key={val.acad_id}>
                                                    <Link to={global.variables.strDomainUrl + "academic-details/" + val.acad_pagename}>{val.acad_course_name}</Link>
                                                </li>
                                            )

                                        })
                                    }
                                    <li className="list-group-item wow fadeInUp"><Link to="/admissions">Admissions</Link></li>
                                </ul>
                            </div>
                            <div className="d-lg-block d-none">
                                <h6 className="text-uppercase fw-semibold wow fadeInUp">Research</h6>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item wow fadeInUp"><Link to="/research">Overview</Link></li>

                                    {
                                        researchcat.length > 0 ?
                                            researchcat.map((val) => {
                                                return (
                                                    <li className="list-group-item wow fadeInUp" key={val.rcategory_id}><Link to={global.variables.strDomainUrl + "research-project/" + val.rcategory_id}>{val.rcategory_name}</Link></li>
                                                )
                                            })
                                            : null
                                    }
                                </ul>
                            </div>
                            <div className='d-flex d-md-block justify-content-between gap-3'>
                                <h6 className="text-uppercase fw-semibold wow fadeInUp"><Link to="/home">Home</Link></h6>
                                <h6 className="text-uppercase fw-semibold wow fadeInUp"><Link to="/about">About Us</Link></h6>
                                <h6 className="text-uppercase fw-semibold d-md-block d-none wow fadeInUp"><Link to="/people">People</Link></h6>
                                <h6 className="text-uppercase fw-semibold d-md-block d-none wow fadeInUp"><Link to="/seminars/recent">Seminars</Link></h6>
                                <h6 className="text-uppercase fw-semibold d-md-block d-none wow fadeInUp"><Link to={ newstypeyear != null ? "/news/" + newstypeyear : "/news/" + new Date().getFullYear()}>News & Events</Link></h6>
                                <h6 className="text-uppercase fw-semibold d-md-block d-none wow fadeInUp"><Link to="/Collaboration">Collaborations</Link></h6>
                                <h6 className="text-uppercase fw-semibold d-md-block d-none wow fadeInUp"><Link to="/recruitment">Recruitment</Link></h6>
                                <h6 className="text-uppercase fw-semibold wow fadeInUp"><Link to="/contact">Contact Us</Link></h6>
                                {/* <h6 className="text-uppercase fw-semibold wow fadeInUp"><Link to="">Webmail</Link></h6> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ScrollToTop smooth className='animated fadeInRight'
                viewBox="0 0 24 24"
                svgPath="m12 6.879-7.061 7.06 2.122 2.122L12 11.121l4.939 4.94 2.122-2.122z"
            />
            <div className="bg-ftr py-md-4 py-3 px-2">
                <Container className='text-center text-md-start'>
                    <Row>
                        <Col md={8} lg={6} className='wow fadeInUp'>© {(new Date().getFullYear())}. C-MInDS, IITBombay. All Rights Reserved.  <Link to={"/Sitemap"}>Sitemap</Link></Col>
                        <Col md={4} lg={6} className='text-md-end wow fadeInUp'>Site Created by <Link to="https://www.designscape.co.in/" rel="nofollow">Designscape</Link></Col>
                    </Row>
                </Container>
            </div>
        </footer>
    )
} 